/* You can add global styles to this file, and also import other style files */
// @import './loader';
@import './loader';

:root {
  --primary-color: hsl(0, 0%, 100%);
  --primary-border: hsl(0, 0%, 100%);
}

body,
html {
  margin: 0;
  padding: 0;
}

html {
  font-family: Montserrat;
  background-color: hsl(0, 0%, 0%);
  color: var(--primary-color);
}
