.maryl-loader
	$dots: 8
	$size: 20vh
	$angle: 360deg / $dots
	$dot-color: #000

	display: flex
	position: fixed
	top: 0
	left: 0
	bottom: 0
	right: 0
	background-color: rgba(#000, 0.2)
	z-index: 99

	&__spinner
		margin: auto auto
		width: $size
		height: $size
		position: relative
		display: flex

	&__circle
		width: 100%
		height: 100%
		position: absolute
		left: 0
		top: 0

		&::before
			content: ''
			display: block
			margin: 0 auto
			width: 15%
			height: 15%
			background-color: $dot-color
			border-radius: 100%
			animation: sk-circleBounceDelay 0.1s*$dots infinite ease-in-out both

	@for $i from 1 to $dots
		.vivid-loader__circle--no#{$i}
			transform: rotate($angle * $i)
			&::before
				animation-delay: (-0.1s * ($dots - $i))

@keyframes sk-circleBounceDelay
	0%,
	80%,
	100%
		transform: scale(0.3)
		opacity: 0.1
	40%
		transform: scale(1)
		opacity: 1
